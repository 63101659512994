import { ListItem, UnorderedList } from "@chakra-ui/react";

// interface Props {
//   includes: string;
//   alsoIncludes: string;
//   uuid: string;
//   includesList: string[];
// }

const Includes = ({ includesList }) => {
  return (
    <>
      {/* <UnorderedList>
        {includesList &&
          includesList.map((item, index) => (
            <ListItem key={index}>{item}</ListItem>
          ))}
      </UnorderedList> */}
      <UnorderedList>
        {/* {alsoIncludes && <ListItem>{alsoIncludes}</ListItem>} */}
        <ListItem>Unlimited streaming</ListItem>
        <ListItem>
          Listen on the go in the Songcards mobile app (in Collector Beta)
        </ListItem>

        <ListItem>HQ WAV and MP3</ListItem>
        {/* <ListItem>A listing on your Collectors page</ListItem> */}
        <ListItem>First dibs on future Songcards</ListItem>
        <ListItem>Your name on the Songcard</ListItem>
        <ListItem>Proof Of Fan</ListItem>
      </UnorderedList>
    </>
  );
};

export default Includes;
